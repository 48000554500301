import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';
import Layout from '../components/Layout';
import data from '../config/site_data.json';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../components/Grid';
import {Heading} from '../components/Typography';
import DynamicImage from '../components/DynamicImage';
import PageHero from '../components/shared/hero';
import CleaningTypes from '../components/Page/office-cleaning/CleaningTypes';
import FAQ from '../components/Page/FAQ';
import BookNowForm from '../components/shared/BookNowForm';

const InfoWrapper = styled(Section)`
  ${tw`relative`}

  @media ${props => props.theme.screens.xl} {
    margin-top: 180px;
  }
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 100%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 100%;
    margin: 0;
  }

  @media ${props => props.theme.screens.xl} {
    max-width: 564px;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`my-6  text-secondary`}
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-left: auto;

  @media ${props => props.theme.screens.md} {
    // position: absolute;
    // right: 0;
    // width: 100%;
    // top: 50%;
    // transform: translateY(-50%);
    // max-width: 637px;
  }
  @media ${props => props.theme.screens.lg} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 637px;
    width: 50%;
  }
`;

export const FaqFormWrapper = styled.div`
  max-width: 660px;
  margin: 0 auto;

  @media ${props => props.theme.screens.xl} {
    ${tw`flex justify-center`}
    max-width: none;

    form {
      ${tw`mx-auto`}
      flex-basis: 495px;
    }
  }
`;

const WindowWashing = props => {
  const {language, t} = useI18next();
  const {background, backgroundMobile, backgroundTablet, faq, formBackground} =
    props.data;
  const {heading, text, info, button_text} = data.window_washing;
  const {title, description, image} = info;

  return (
    <Layout headerStyle="windowWashing">
      <SEO
        title={t('window_washing_seo_title')}
        description={t('window_washing_seo_description')}
        keywords={t('window_washing_seo_keywords', {returnObjects: true})}
        lang={language}
      />
      <PageHero
        content={{
          heading,
          text,
          button: button_text,
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <InfoWrapper>
        <ImageContainer>
          <DynamicImage filename={image} />
        </ImageContainer>
        <Container>
          <Content>
            <StyledHeading>{t(title)}</StyledHeading>
            <Text dangerouslySetInnerHTML={{__html: t(description)}} />
          </Content>
        </Container>
      </InfoWrapper>
      <div style={{marginBottom: 140}}>
        <CleaningTypes wrapperProps={{marginTop: '140px'}} />
      </div>
      <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
      <BookNowForm
        background={formBackground}
        heading={t('window_washing_book_now_heading')}
      />
    </Layout>
  );
};

export default WindowWashing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "window_washing"
            "seo_window_washing"
            "office_cleaning"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "window-washing-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(
      relativePath: {eq: "window-washing-hero-mobile.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(
      relativePath: {eq: "window-washing-hero-tablet.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    formBackground: file(relativePath: {eq: "window-washing-form.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
  }
`;
