import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Image: React.FC<{filename: string; alt?: string}> = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {extension: {in: ["jpg", "png"]}}) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <GatsbyImage
          alt={props.alt}
          image={getImage(image.node.childImageSharp)}
          style={{width: '100% '}}
        />
      );
    }}
  />
);

export default Image;
